import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import {firebaseConfig, recaptchaSiteKey} from '@/config'

const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(recaptchaSiteKey),
	isTokenAutoRefreshEnabled: true
})

import '@/assets/scss/index.scss'

createApp(App).use(store).use(router).mount('#app')
