export const DEFAULT_TOKEN_COST = 0.015

export const DEFAULT_MIN_AMOUNT = 500
export const DEFAULT_MAX_AMOUNT = 10000

export const isProd = process.env.NODE_ENV === 'production'

export const firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
	measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

export const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
