import { Commit, useStore } from "vuex";
import { getDatabase, ref, onValue } from "firebase/database";
import {IApp, IAppSettings, TTimer,} from '@/types/app'
import {DEFAULT_MAX_AMOUNT, DEFAULT_MIN_AMOUNT, DEFAULT_TOKEN_COST} from '@/config'
import { parseCountdown } from '@/utils/helper'
import {IAllocationRequest} from "@/types/user";

export default {
	state: (): IApp => ({
		settings: {
			ethWallet: '',
			bscWallet: '',
			tokenCost: DEFAULT_TOKEN_COST,
			allocation: {
				minAmount: DEFAULT_MIN_AMOUNT,
				maxAmount: DEFAULT_MAX_AMOUNT
			},
			appSteps: {
				eligibleTimer: +new Date('2022-01-01T00:00:00.000Z'),
				kycTimer: +new Date('2023-01-15T00:00:00.000Z'),
				paymentTimer: +new Date('2023-02-01T00:00:00.000Z'),
				_40_allocationConfirmStopped: false,
				_50_saftStarted: false,
				_60_paymentConfirmStopped: false
			},
		},
		countdown: {
			data: {
				eligibleTimer: '',
				kycTimer: '',
				paymentTimer: ''
			},
			_interval: 0,
		}
	}),
	getters: {
		timers (state: IApp): Record<TTimer, string> {
			return state.countdown.data
		},
		tokenCost (state: IApp): number {
			return state.settings.tokenCost
		},
		smartContractAddress (state: IApp): string {
			return (<IAllocationRequest>useStore().getters["user/allocation"])?.walletType === "binance-smart-chain"
				? state.settings.bscWallet
				: (<IAllocationRequest>useStore().getters["user/allocation"])?.walletType === "ethereum"
					? state.settings.ethWallet
					: ''
		}
	},
	mutations: {
		setAppSettings(state: IApp, appSettings: IAppSettings): void {
			(<TTimer[]>["eligibleTimer", "kycTimer", "paymentTimer"]).forEach((t) => {
				state.settings.appSteps[t] = + new Date(appSettings.appSteps[t])
			})
			state.settings.appSteps._40_allocationConfirmStopped = appSettings.appSteps._40_allocationConfirmStopped
			state.settings.appSteps._50_saftStarted = appSettings.appSteps._50_saftStarted
			state.settings.appSteps._60_paymentConfirmStopped = appSettings.appSteps._60_paymentConfirmStopped
			state.settings.allocation.minAmount = appSettings.allocation?.minAmount ?? DEFAULT_MIN_AMOUNT
			state.settings.allocation.maxAmount = appSettings.allocation?.maxAmount ?? DEFAULT_MAX_AMOUNT
			state.settings.ethWallet = appSettings.ethWallet || ''
			state.settings.bscWallet = appSettings.bscWallet || ''
		},
	
		startCountdown (state: IApp): void {
			if (state.countdown._interval) {
				clearInterval(state.countdown._interval)
				state.countdown._interval = 0
			}
			state.countdown._interval = setInterval(() => {
				(<TTimer[]>["eligibleTimer", "kycTimer", "paymentTimer"]).forEach((t) => {
					state.countdown.data[t] = parseCountdown(state.settings.appSteps[t] - Date.now())
				})
			}, 1000)
		}
	},
	actions: {
		async init({ commit }: { commit: Commit }): Promise<void> {
			try	{
				onValue(ref(getDatabase(), `/dashboard/settings`), (snap) => {
					commit("setAppSettings", snap.val())
				})
				commit('startCountdown')
			} catch(e) {
				console.log('Unable to fetch app settings', e.message)
			}
		}
	},
	modules: {
	},
	namespaced: true
}
