import { User, getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, onValue } from "firebase/database";
import { Commit, Dispatch } from 'vuex'
import browserInfo, {IDevice} from '@/modules/device'

import {
	IUser,
	// IPermissions,
	TLoginState,
	IAllocationRequest,
	// TStateParam,
	TUserState,
	IDashboardInit, ISAFTShareLink
} from '@/types/user'

import router from '@/router'

export default {
	state: (): IUser => ({
		currentState: null,
		customMessage: '',
		permissions: {
			roles: {},
			rights: {}
		},
		loginState:  ['true', 'login'].includes(localStorage.getItem('logged') || '') ? "login" : "logout",
		fbUser: null,
		allocation: undefined,
		// allocation: {
		// 	maxInvestedAmount: 0,
		// 	walletType: null,
		// 	walletAddress: ''
		// },
		saftShareLink: undefined,
		paymentConfirmed: 0,
		allocationConfirmed: 0
	}),
	getters: {
		isLogged (state: IUser): boolean {
			return state.loginState === 'login'
		},
		isDev (state: IUser): boolean {
			return !!state.permissions.rights['dev']
		},
		isPro (state: IUser): boolean {
			return !!state.permissions.roles['pro']
		},
		user (state: IUser): User | null {
			return state.fbUser
		},
		currentState (state: IUser): TUserState | null {
			return state.currentState
		},
		allocation (state: IUser): IAllocationRequest | undefined {
			return state.allocation
		},
		saftLink (state: IUser): string {
			return state.saftShareLink?.id ? `https://app.pandadoc.com/s/${state.saftShareLink.id}` : ''
		},
		allocationConfirmed (state: IUser): number {
			return state.allocationConfirmed
		},
		paymentConfirmed (state: IUser): number {
			return state.paymentConfirmed
		},
		customMessage (state: IUser): string {
			return state.customMessage || ''
		}
	},
	mutations: {
		dashboardInit (state: IUser, payload: IDashboardInit): void {
			state.permissions.rights = payload.rights || {}
			state.permissions.roles = payload.roles || {}
			state.allocation = payload.allocation
		},
		setUserAllocation (state: IUser, payload: IAllocationRequest | undefined): void {
			state.allocation = payload
		},
		setUserAllocationConfirmed (state: IUser, payload: number | undefined): void {
			state.allocationConfirmed = payload || 0
		},
		setUserPaymentConfirmed (state: IUser, payload: number | undefined): void {
			state.paymentConfirmed = payload || 0
		},
		setUserSaftSharedLink (state: IUser, payload: ISAFTShareLink | undefined): void {
			state.saftShareLink = payload
		},
		setLoginState (state: IUser, payload: TLoginState): void {
			state.loginState = payload
			localStorage.setItem('logged', payload)
		},
		setUser (state: IUser, payload: User): void {
			state.fbUser = payload
		},
		setUserCurrentState (state: IUser, newUserState: TUserState): void {
			state.currentState = newUserState
		},
		setUserCustomMessage (state: IUser, message: string): void {
			state.customMessage = message
		}
	},
	actions: {
		async initUserData({ commit }: { commit: Commit }, payload: TLoginState): Promise<void> {
			let dashboardInit: IDashboardInit = {
				roles: {}, rights: {},
				allocation: undefined
			}
			if (payload === "login") {
				const loginFromDashboard = httpsCallable<null, IDashboardInit>(getFunctions(), 'dashboardInit')
				const params = await loginFromDashboard()
				if (params.data) {
					dashboardInit = params.data
				}
			}
			commit('dashboardInit', dashboardInit)
		},
		async setUser({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, payload: User | null): Promise<void> {
			if (payload) {
				if ((await payload.getIdTokenResult()).claims.verified) {
					try {
						commit('setUser', payload)
						const state: TLoginState = 'login'
						await dispatch('initUserData', state)
						commit('setLoginState', state)
						onValue(ref(getDatabase(), `/dashboard/users/${payload.uid}/currentState`), (snap) => {
							commit('setUserCurrentState', snap.val())
						})
						onValue(ref(getDatabase(), `/dashboard/users/${payload.uid}/customMessage`), (snap) => {
							commit('setUserCustomMessage', snap.val())
						})
						onValue(ref(getDatabase(), `/dashboard/users/${payload.uid}/allocationConfirmed`), (snap) => {
							commit('setUserAllocationConfirmed', snap.val())
						})
						onValue(ref(getDatabase(), `/dashboard/users/${payload.uid}/paymentConfirmed`), (snap) => {
							commit('setUserPaymentConfirmed', snap.val())
						})
						onValue(ref(getDatabase(), `/dashboard/users/${payload.uid}/SAFT/link`), (snap) => {
							commit('setUserSaftSharedLink', snap.val())
						})
					} catch (e) {
						console.error('user/setUser dispatching error', e.message)
					}
				} else {
					await dispatch('loginWithCustomToken')
					router.push('/').catch((e)=>{ console.log(e.message) })
				}
			} else {
				await dispatch('initUserData', 'logout')
				commit('setLoginState', 'logout')
			}
		},
		async loginWithCustomToken({ state, commit, dispatch }: { state: IUser, commit: Commit, dispatch: Dispatch }, totp: string): Promise<TLoginState> {
			try {
				const device = browserInfo.getInfo()
				const functionName = totp ? 'mfaCustomToken' : 'customToken'
				const customToken = httpsCallable<{ device: IDevice, totp: string }, { token: string } >(getFunctions(), functionName, )
				const ctResponse = await customToken({ device, totp })
				const res = await signInWithCustomToken(getAuth(), ctResponse.data.token)
				await dispatch("setUser", res.user)
				return Promise.resolve(state.loginState as TLoginState)
			} catch (e) {
				if (e.code === 'functions/permission-denied' && e.message.indexOf('needMfa') !== -1) {
					const state: TLoginState = e.message === 'needMfaEmail' ? 'needMfaEmail' : 'needMfa'
					commit('setLoginState', state)
					return Promise.resolve(state)
				} else {
					return Promise.reject(e)
				}
			}
		},
		async allocationRequest({ commit }: { commit: Commit }, allocationRequest: IAllocationRequest): Promise<IAllocationRequest> {
			try {
				const allocationRequestCF = httpsCallable<IAllocationRequest, IAllocationRequest>(getFunctions(), 'dashboardAllocationRequest')
				const res = await allocationRequestCF(allocationRequest)
				commit("setUserAllocation", res.data)
				return Promise.resolve(res.data)
			} catch (e) {
				return Promise.reject(e)
			}
		},
		async setUserKycStarted ({ commit }: { commit: Commit }): Promise<void> {
			commit("setUserCurrentState", <TUserState>"kyc_started")
		},
		async setUserCurrentState ({ commit }: { commit: Commit }, state: TUserState): Promise<void> {
			commit("setUserCurrentState", state)
		},
		async startSaftSign ({ commit }: { commit: Commit }): Promise<void> {
			await httpsCallable<IAllocationRequest, IAllocationRequest>(getFunctions(), 'dashboardStartSAFTSign')()
			commit('setUserCurrentState', <TUserState>"saft_started")
		}
	},
	modules: {
	},
	namespaced: true
}
