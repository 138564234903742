<template>
  <app-header v-if="!store.getters['user/isLogged']" />
  <main class="app__content">
    <router-view />
  </main>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import { defineComponent, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default defineComponent({
  name: 'App',
  components: { AppHeader },
	setup () {
		const store = useStore()
		const auth = getAuth();
		const router = useRouter()

		onAuthStateChanged(auth, async (user) => {
			try {
				if (user) {
					await store.dispatch('user/setUser', user)
				} else {
					await store.dispatch('user/setUser', null)
					await router.push('/signin')
				}
			} catch (e) {
				console.debug('onAuthStateChanged error', e.message)
			}
		});

		onBeforeMount(async () => {
			await store.dispatch('app/init')
		})

		return { store }
	}
});
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app__content {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  & > * {
    width: 100%;
  }
}
</style>
