const ALERT_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  WAIT: 'wait'
};

const NAV = {
  ACCOUNT: 'account',
  TOKEN_LAUNCH: 'tokenLaunch',
  SEED_ROUND: 'seedRound',
  SUPPORT: 'support'
};
const SUBNAV = {
  KYC: 'kyc',
  ALLOCATION: 'allocation',
  CONTRACT: 'contract',
  PAYMENT: 'payment',
  CONFIRMATION: 'confirmation'
};

const NAV_ROUTES = {
  [NAV.ACCOUNT]: { PATH: '/account', NAME: 'Account' },
  [NAV.TOKEN_LAUNCH]: { PATH: '/', NAME: 'TokenLaunch' },
  [NAV.SUPPORT]: { PATH: '/support', NAME: 'Support' },
  [NAV.SEED_ROUND]: {
    PATH: '/seed-round',
    NAME: 'SeedRound',
    CHILDREN: [SUBNAV.KYC, SUBNAV.ALLOCATION, SUBNAV.CONTRACT, SUBNAV.PAYMENT, SUBNAV.CONFIRMATION]
  },
  [SUBNAV.KYC]: { PATH: 'kyc', NAME: 'Kyc', TIMER: 'kycTimer' },
  [SUBNAV.ALLOCATION]: { PATH: 'allocation', NAME: 'Allocation', TIMER: 'kycTimer' },
  [SUBNAV.CONTRACT]: { PATH: 'contract', NAME: 'Contract', TIMER: 'paymentTimer' },
  [SUBNAV.PAYMENT]: { PATH: 'payment', NAME: 'Payment', TIMER: 'paymentTimer' },
  [SUBNAV.CONFIRMATION]: { PATH: 'confirmation', NAME: 'Confirmation' }
};

const USER_STATES = {
  KYC_NOT_ELIGIBLE: 'kyc_not_eligible',
  KYC_ELIGIBLE: 'kyc_eligible',
  KYC_STARTED: 'kyc_started',
  KYC_FAILED: 'kyc_failed',
  KYC_SUCCESS: 'kyc_success',
  KYC_NOT_COMPLETED: 'kyc_not_completed',
  ALLOCATION_NOT_REQUESTED: 'allocation_not_requested',
  ALLOCATION_REQUESTED: 'allocation_requested',
  ALLOCATION_NO_ALLOCATION: 'allocation_no_allocation',
  ALLOCATION_CONFIRMED: 'allocation_confirmed',
  SAFT_ELIGIBLE: 'saft_eligible',
  SAFT_STARTED: 'saft_started',
  SAFT_NOT_SIGNED: 'saft_not_signed',
  SAFT_SIGNED: 'saft_signed',
  PAYMENT_ELIGIBLE: 'payment_eligible',
  PAYMENT_NOT_RECEIVED: 'payment_not_received',
  PAYMENT_AML_FAILED: 'payment_aml_failed',
  PAYMENT_CONFIRMED: 'payment_confirmed',
  PAYMENT_LESS_THAN_MIN: 'payment_less_than_min'
};

const USER_STATE_TYPES = {
  WAIT: 'wait',
  OPEN: 'open',
  CLOSED: 'closed'
}

const BLOCKCHAINS = {
  ETHEREUM: 'ethereum',
  BINANCE_SMART_CHAIN: 'binance-smart-chain'
}

const BLOCKCHAINS_DATA = {
  [BLOCKCHAINS.ETHEREUM]: {
    name: 'Ethereum (ETH)',
  },
  [BLOCKCHAINS.BINANCE_SMART_CHAIN]: {
    name: 'Binance Smart Chain (BSC)',
  },
};

const USER_STATE_BY_TYPES = {
  [USER_STATE_TYPES.WAIT]: [
    USER_STATES.ALLOCATION_REQUESTED,
    USER_STATES.ALLOCATION_CONFIRMED,
    USER_STATES.PAYMENT_ELIGIBLE
  ],
  [USER_STATE_TYPES.CLOSED]: [
    USER_STATES.KYC_NOT_ELIGIBLE,
    USER_STATES.KYC_NOT_COMPLETED,
    USER_STATES.KYC_FAILED,
    USER_STATES.ALLOCATION_NOT_REQUESTED,
    USER_STATES.ALLOCATION_NO_ALLOCATION,
    USER_STATES.SAFT_NOT_SIGNED,
    USER_STATES.PAYMENT_NOT_RECEIVED,
    USER_STATES.PAYMENT_LESS_THAN_MIN,
    USER_STATES.PAYMENT_AML_FAILED
  ]
}

const ROUTES_BY_STATE = {
  [USER_STATES.KYC_NOT_ELIGIBLE]: { name: NAV_ROUTES[SUBNAV.KYC].NAME },
  [USER_STATES.KYC_ELIGIBLE]: { name: NAV_ROUTES[SUBNAV.KYC].NAME },
  [USER_STATES.KYC_STARTED]: { name: NAV_ROUTES[SUBNAV.KYC].NAME },
  [USER_STATES.KYC_FAILED]: { name: NAV_ROUTES[SUBNAV.KYC].NAME },
  [USER_STATES.KYC_NOT_COMPLETED]: { name: NAV_ROUTES[SUBNAV.KYC].NAME },
  [USER_STATES.KYC_SUCCESS]: { name: NAV_ROUTES[SUBNAV.ALLOCATION].NAME },
  [USER_STATES.ALLOCATION_NOT_REQUESTED]: { name: NAV_ROUTES[SUBNAV.ALLOCATION].NAME },
  [USER_STATES.ALLOCATION_REQUESTED]: { name: NAV_ROUTES[SUBNAV.ALLOCATION].NAME },
  [USER_STATES.ALLOCATION_NO_ALLOCATION]: { name: NAV_ROUTES[SUBNAV.ALLOCATION].NAME },
  [USER_STATES.ALLOCATION_CONFIRMED]: { name: NAV_ROUTES[SUBNAV.ALLOCATION].NAME },
  [USER_STATES.SAFT_ELIGIBLE]: { name: NAV_ROUTES[SUBNAV.CONTRACT].NAME },
  [USER_STATES.SAFT_STARTED]: { name: NAV_ROUTES[SUBNAV.CONTRACT].NAME },
  [USER_STATES.SAFT_NOT_SIGNED]: { name: NAV_ROUTES[SUBNAV.CONTRACT].NAME },
  [USER_STATES.SAFT_SIGNED]: { name: NAV_ROUTES[SUBNAV.PAYMENT].NAME },
  [USER_STATES.PAYMENT_ELIGIBLE]: { name: NAV_ROUTES[SUBNAV.CONFIRMATION].NAME },
  [USER_STATES.PAYMENT_NOT_RECEIVED]: { name: NAV_ROUTES[SUBNAV.CONFIRMATION].NAME },
  [USER_STATES.PAYMENT_AML_FAILED]: { name: NAV_ROUTES[SUBNAV.CONFIRMATION].NAME },
  [USER_STATES.PAYMENT_CONFIRMED]: { name: NAV_ROUTES[SUBNAV.CONFIRMATION].NAME },
  [USER_STATES.PAYMENT_LESS_THAN_MIN]: { name: NAV_ROUTES[SUBNAV.CONFIRMATION].NAME }
}

const OPTIONS_BY_STATE = {
  [USER_STATES.KYC_NOT_ELIGIBLE]: { autoRedirect: true },
  [USER_STATES.KYC_ELIGIBLE]: { autoRedirect: true },
  [USER_STATES.KYC_STARTED]: { autoRedirect: true },
  [USER_STATES.KYC_FAILED]: { autoRedirect: true },
  [USER_STATES.KYC_NOT_COMPLETED]: { autoRedirect: true },
  [USER_STATES.KYC_SUCCESS]: { autoRedirect: true },
  [USER_STATES.ALLOCATION_NOT_REQUESTED]: { autoRedirect: true },
  [USER_STATES.ALLOCATION_REQUESTED]: { autoRedirect: true },
  [USER_STATES.ALLOCATION_NO_ALLOCATION]: { autoRedirect: true },
  [USER_STATES.ALLOCATION_CONFIRMED]: { autoRedirect: true },
  [USER_STATES.SAFT_ELIGIBLE]: { autoRedirect: true },
  [USER_STATES.SAFT_STARTED]: { autoRedirect: false },
  [USER_STATES.SAFT_NOT_SIGNED]: { autoRedirect: true },
  [USER_STATES.SAFT_SIGNED]: { autoRedirect: true },
  [USER_STATES.PAYMENT_ELIGIBLE]: { autoRedirect: true },
  [USER_STATES.PAYMENT_NOT_RECEIVED]: { autoRedirect: true },
  [USER_STATES.PAYMENT_AML_FAILED]: { autoRedirect: true },
  [USER_STATES.PAYMENT_CONFIRMED]: { autoRedirect: true },
  [USER_STATES.PAYMENT_LESS_THAN_MIN]: { autoRedirect: true }
}

const SAMSUB_LNGS = [
  { "code": "en", "language": "English" },
  { "code": "ru", "language": "Russian" },
  { "code": "de", "language": "German" },
  { "code": "et", "language": "Estonian" },
  { "code": "pt", "language": "Portuguese" },
  { "code": "pt-br", "language": "Portuguese (Brazilian)" },
  { "code": "hu", "language": "Hungarian" },
  { "code": "zh", "language": "Chinese Simplified" },
  { "code": "zh-tw", "language": "Chinese Traditional" },
  { "code": "th", "language": "Thai" },
  { "code": "id", "language": "Indonesian" },
  { "code": "es", "language": "Spanish" },
  { "code": "tr", "language": "Turkish" },
  { "code": "vi", "language": "Vietnamese" },
  { "code": "ar", "language": "Arabic" },
  { "code": "hi", "language": "Hindi" },
  { "code": "ms", "language": "Malay" },
  { "code": "ur", "language": "Urdu" },
  { "code": "bn", "language": "Bengali" },
  { "code": "fa", "language": "Persian" },
  { "code": "fl", "language": "Philippine" },
  { "code": "fr", "language": "French" },
  { "code": "ja", "language": "Japanese" },
  { "code": "ko", "language": "Korean" },
  { "code": "uk", "language": "Ukrainian" },
  { "code": "ro", "language": "Romanian" },
  { "code": "fi", "language": "Finnish" },
  { "code": "no", "language": "Norwegian" },
  { "code": "cs", "language": "Czech" },
  { "code": "it", "language": "Italian" },
  { "code": "nl", "language": "Dutch" },
  { "code": "pl", "language": "Polish" },
  { "code": "my", "language": "Burmese" },
  { "code": "lo", "language": "Lao" },
  { "code": "km", "language": "Central Khmer" }
]

export default {
  NAV,
  SUBNAV,
  NAV_ROUTES,
  USER_STATES,
  ALERT_TYPES,
  SAMSUB_LNGS,
  BLOCKCHAINS,
  ROUTES_BY_STATE,
  USER_STATE_TYPES,
  OPTIONS_BY_STATE,
  BLOCKCHAINS_DATA,
  USER_STATE_BY_TYPES
} as const;
