export const parseCountdown = (value: number): string => {
  // console.log('🚀 ~ parseCountdown ~ value', value)
  if (!value || value <= 0) return ''
  const d = Math.floor(value / (1000 * 60 * 60 * 24));
  const h = Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const m = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
  const s = Math.floor((value % (1000 * 60)) / 1000);
  const f = (v: number) => `0${v}`.slice(-2) // Add zero to short number
  return `${d ? d + 'd ': ''}${f(h)}:${f(m)}:${f(s)}`
}
