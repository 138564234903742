const PREFIX = 'GoodCrypto Dashboard';
const GLUE = ' | ';

const TITLE: Record<string, string> = {
  Signin: 'Sign In',
  Account: 'Account',
  Allocation: 'Allocation',
  Confirmation: 'Confirmation',
  Contract: 'Contract',
  Home: 'Home',
  Kyc: 'Kyc',
  Payment: 'Payment',
  SeedRound: 'SeedRound',
  Support: 'Support',
  TokenLaunch: 'TokenLaunch',
}

export default function (name: string): string {
  const title = TITLE[name]
  if (!title) return PREFIX
  return [PREFIX, title].join(GLUE)
}
