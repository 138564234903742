import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import CONSTS from '@/utils/constants';
import pageTitle from '@/utils/pageTitle';
import { getRouteByUserState } from '@/utils/routerHelper';

const { ACCOUNT, TOKEN_LAUNCH, SEED_ROUND, SUPPORT } = CONSTS.NAV
const { KYC, ALLOCATION, CONTRACT, PAYMENT, CONFIRMATION } = CONSTS.SUBNAV
const ROUTES = CONSTS.NAV_ROUTES

const routes: Array<RouteRecordRaw> = [
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { requiredAuth: true },
    children: [
      {
        path: ROUTES[ACCOUNT].PATH,
        name: ROUTES[ACCOUNT].NAME,
        meta: { requiredAuth: true },
        props: true,
        component: () => import('../views/Account.vue')
      },
      {
        path: ROUTES[TOKEN_LAUNCH].PATH,
        name: ROUTES[TOKEN_LAUNCH].NAME,
        meta: { requiredAuth: true },
        component: () => import('../views/TokenLaunch.vue')
      },
      {
        path: ROUTES[SEED_ROUND].PATH,
        name: ROUTES[SEED_ROUND].NAME,
        meta: { requiredAuth: true },
        component: () => import('../views/SeedRound.vue'),
        children: [{
          path: '',
          redirect (to) {
            const seedRoundRoutePath = CONSTS.NAV_ROUTES[CONSTS.NAV.SEED_ROUND].PATH
            if (to.path === seedRoundRoutePath) {
              return getRouteByUserState(store.getters['user/currentState'])
            }
            return to
          }
        },
        {
          path: ROUTES[KYC].PATH,
          name: ROUTES[KYC].NAME,
          meta: { requiredAuth: true },
          component: () => import('../views/Kyc.vue')
        },
        {
          path: ROUTES[ALLOCATION].PATH,
          name: ROUTES[ALLOCATION].NAME,
          meta: { requiredAuth: true },
          component: () => import('../views/Allocation.vue')
        },
        {
          path: ROUTES[CONTRACT].PATH,
          name: ROUTES[CONTRACT].NAME,
          meta: { requiredAuth: true },
          component: () => import('../views/Contract.vue')
        },
        {
          path: ROUTES[PAYMENT].PATH,
          name: ROUTES[PAYMENT].NAME,
          meta: { requiredAuth: true },
          component: () => import('../views/Payment.vue')
        },
        {
          path: ROUTES[CONFIRMATION].PATH,
          name: ROUTES[CONFIRMATION].NAME,
          meta: { requiredAuth: true },
          component: () => import('../views/Confirmation.vue')
        }]
      },
      {
        path: ROUTES[SUPPORT].PATH,
        name: ROUTES[SUPPORT].NAME,
        meta: { requiredAuth: true },
        component: () => import('../views/Support.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next): void => {
  const isAuthenticated = store.getters['user/isLogged'];
  if (to.name) document.title = pageTitle(String(to.name));
  if (to.meta.requiredAuth && !isAuthenticated) return next({ name: 'Signin' });
  const currentUserState = store.getters['user/currentState'];
  if (currentUserState !== null) {
    const seedRoundRouteName = CONSTS.NAV_ROUTES[CONSTS.NAV.SEED_ROUND].NAME;
    const isSeedRoundChild = to.matched.some(
      (r) => to.name !== seedRoundRouteName && r.name === seedRoundRouteName
    );
    if (isSeedRoundChild) {
      const nextValidRoute = getRouteByUserState(currentUserState);
      if (!nextValidRoute || nextValidRoute.name !== to?.name) return next(nextValidRoute);
    }
  }
  next();
});

export default router;
