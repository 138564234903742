<template>
  <svg
    v-if="filepath"
    :viewBox="viewBox"
    :width="width"
    :height="height"
    :fill="fill"
    :stroke="stroke"
  />
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { parse } from 'postsvg';
import render from 'posthtml-render';

const cache = new Map();

export default {
  name: 'Icon',
  props: {
    name: { type: String, required: true },
    width: { type: [Number, String], default: 40 },
    height: { type: [Number, String], default: 40 },
    fill: { type: String, default: null },
    stroke: { type: String, default: null },
    generateReadyEvent: { type: Boolean, default: false }
  },
  data: () => ({
    svgString: '',
    
  }),
  computed: {
    filepath() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const icon = require(`@/assets/icons/${this.name}.svg`).toString()
        return icon;
      } catch (error) {
        return null;
      }
    },

    parsedSVG() {
      return this.svgString ? parse(this.svgString) : null;
    },

    viewBox() {
      return this.parsedSVG ? this.parsedSVG.root.attrs.viewBox : '0 0 20 20';
    }
  },
  watch: {
    filepath: { immediate: true, handler: 'loadFile' },
    svgString: 'refreshSvg',
    stroke: 'refreshSvg',
    fill: 'refreshSvg'
  },
  methods: {
    loadFile() {
      this.getSvgIconText()
        .then((responseText) => (this.svgString = responseText))
        .catch((error) => console.error(error));
    },

    async getSvgIconText() {
      const url = this.filepath;

      if (!cache.has(url)) {
        try {
          cache.set(
            url,
            fetch(url).then((r) => r.text())
          );
        } catch (e) {
          cache.delete(url);
        }
      }

      return cache.has(url)
        ? await cache.get(url)
        : Promise.reject(new Error('No SVG in the local cache'));
    },

    refreshSvg() {
      Promise.resolve(this.parsedSVG)
        .then(svgTree => {
          svgTree.each('path', node => {
            if (!node.attrs._fill) node.attrs._fill = node.attrs.fill
            node.attrs.fill = this.fill || node.attrs._fill
            if (!node.attrs._stroke) node.attrs._stroke = node.attrs.stroke
            node.attrs.stroke = this.stroke || node.attrs._stroke
          });
          return svgTree;
        })
        .then((svgTree) => render(svgTree.root.content))
        .then((svgHtml) => (this.$el.innerHTML = svgHtml))
        .then(() => this.generateReadyEvent && this.$emit('ready'))
        .catch((error) => {
          this.$emit('error', error);
        });
    }
  }
};
</script>

<style>
svg {
  display: block;
  transition: fill 0.25s ease, stroke 0.25s ease;
}
</style>
